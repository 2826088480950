<template>
  <div>
    <el-dialog class="custom-dialog" :title="'用户'+(formType?'编辑':'添加')" :visible.sync="dialogVisible" width="520px"
      :append-to-body="true" @close="closeAlert">
      <el-form ref="form" :rules="formRules" :model="form" label-width="80px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="用户名" prop="userName" class="custom-form-item">
              <el-input v-model="form.userName" placeholder="请输入用户名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="登录名" prop="userLogin" class="custom-form-item">
              <el-input v-model="form.userLogin" placeholder="请输入登录名"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item v-if="!formType" label="所属公司" prop="companyId" class="custom-form-item">
          <el-select v-model="form.companyId" filterable remote placeholder="请输入关键词" style="width: 100%;"
            :remote-method="remoteMethod" :loading="companyLoading">
            <el-option v-for="(item,index) in companyList" :key="index" :label="item.companyName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="角色" prop="userRole" class="custom-form-item">
              <el-cascader :options="roleOptions" v-model="form.userRole" :props="{ checkStrictly: true,label:'title'}"
                clearable>
              </el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号" prop="userPhone" class="custom-form-item">
              <el-input v-model="form.userPhone" type="number" placeholder="请输入手机号"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12" v-if="!formType">
            <el-form-item label="密码" prop="password" class="custom-form-item">
              <el-input v-model="form.password" type="password" placeholder="请输入密码"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="!formType">
            <el-form-item label="确认密码" prop="repass" class="custom-form-item">
              <el-input v-model="form.repass" type="password" placeholder="请输入确认密码"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="formType" prop="passwords">
            <el-form-item label="密码">
              <el-input value="*******" disabled type="password"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="bottom-btn">
        <el-button type="primary" @click="onSubmit('form')" :loading="loadingAlert" :disabled="loadingAlert">确 定
        </el-button>
        <el-button @click="closeAlert">取 消</el-button>
      </div>
    </el-dialog>
    <el-row>
      <el-col :xs="24" :sm="24" :md="10" :lg="6" class="">
        <el-card class="custom-card mgAll10">
          <div slot="header" class="clearfix">
            <span class="border-l relative pdL10">部门列表</span>
          </div>
          <el-tree style="overflow: auto" :data="departOptions" show-checkbox check-strictly @check-change="checkChange" default-expand-all
            node-key="value" ref="tree" highlight-current :props="defaultProps">
          </el-tree>
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="24" :md="14" :lg="18">
        <el-card class="custom-card mgAll10">
          <div slot="header" class="clearfix">
            <span class="border-l relative pdL10">{{$route.meta.title}}</span>
          </div>
          <div>
            <!-- 搜索 -->
            <el-input v-model="searchForm.name" placeholder="用户名称" class="mgB10 custom-form-input"></el-input>
            <el-button type="primary" @click="search" :loading="loading" :disabled="loading" plain class="mgL10 mgB10">
              搜索
            </el-button>
            <el-button type="success" @click="add" plain class="mgL10 mgB10">添加
            </el-button>
            <!-- 表格 -->
            <el-table :data="tableData" border v-loading="loading" row-key="id">
              <el-table-column prop="userName" :show-overflow-tooltip="true" header-align="center" align="center"
                label="用户名"></el-table-column>
              <el-table-column prop="userLogin" :show-overflow-tooltip="true" header-align="center" align="center"
                label="登录名"></el-table-column>
              <el-table-column prop="userPhone" :show-overflow-tooltip="true" header-align="center" align="center"
                label="手机号">
              </el-table-column>
              <el-table-column prop="companyName" :show-overflow-tooltip="true" header-align="center" align="center"
                label="所属公司">
              </el-table-column>
              <el-table-column prop="enable" :show-overflow-tooltip="true" header-align="center" align="center"
                label="状态" width="120">
                <template slot-scope="scope">
                  <el-switch v-model="scope.row.enable" @change="changeState(scope.row)"></el-switch>
                </template>
              </el-table-column>
              <el-table-column prop="roleName" :show-overflow-tooltip="true" header-align="center" align="center"
                label="角色">
              </el-table-column>
              <el-table-column label="操作" width="210" header-align="center" align="center" fixed="right">
                <template slot-scope="scope">
                  <el-button @click.native.prevent="editRow(scope.row)" size="mini" class="custom-table-btn">
                    编辑
                  </el-button>
                  <el-button @click.native.prevent="resetPassRow(scope.row)" type="primary" size="mini"
                    class="custom-table-btn">
                    重置密码
                  </el-button>
                  <el-button @click.native.prevent="delRow(scope.row)" type="danger" size="mini"
                    class="custom-table-btn">
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <table-page :page="searchForm.page" :limit="searchForm.limit" :total="total" @search-table="feach">
            </table-page>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  export default {
    name: "systemMenu",
    data() {
      var than = this;
      return {
        companyLoading: false,
        companyList: [],
        tableData: [],
        loading: false,
        searchForm: {
          code: "",
          name: "",
          page: 1,
          limit: 10,
        },
        total: 0,
        departOptions: [],
        defaultProps: {
          children: 'children',
          label: 'title'
        },
        roleOptions: [],
        dialogVisible: false,
        formType: false,
        loadingAlert: false,
        form: {
          userLogin: "",
          userName: "",
          userRole: "",
          userPhone: "",
          password: "",
          repass: "",
          id: "",
          companyId: ""
        },
        formRules: {
          companyId: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          userLogin: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          userName: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          userRole: [{
            required: true,
            message: '不可为空',
            trigger: 'change'
          }],
          userPhone: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }, {
            validator: (rule, value, callback) => {
              if (!this.$util.isPoneAvailable(value)) {
                callback(new Error('请输入正确的手机号码!'));
              } else {
                callback();
              }
            },
            trigger: 'blur'
          }],
          password: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          passwords: [{
            required: false,
            message: '不可为空',
            trigger: 'blur'
          }],
          repass: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }, {
            validator: (rule, value, callback) => {
              if (than.form.password !== value) {
                callback(new Error('两次输入的密码不一致!'));
              } else {
                callback();
              }
            },
            trigger: 'blur'
          }]
        }
      }
    },
    mounted() {
      this.search();
      this.departTreeAll()
      this.getRoleTree()
    },
    methods: {
      //状态更改
      changeState(e) {
        this.$axios.Post(this.$api.userEnable, {
          "userId": e.id,
          "enable": e.enable
        }).then(() => {}).catch((err) => {
          if (err.errMsg) {
            this.$message({
              type: 'error',
              message: err.errMsg
            });
          }
          e.enable = !e.enable
        })
      },
      //远程搜索
      remoteMethod(e) {
        if (e) {
          this.companyLoading = true
          this.$axios.Get(this.$api.companyListByName, {
            name: e
          }).then(res => {
            this.companyList = res.data
            this.companyLoading = false
          }).catch(() => {
            this.companyLoading = false
          })
        } else {
          this.companyList = []
        }
      },
      //重置密码
      resetPassRow(row) {
        this.$prompt('请输入重置后的密码', '密码重置', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputType: "text",
          inputValidator: (value) => {
            if (value) {
              return true
            } else {
              return false
            }
          }
        }).then(({
          value
        }) => {
          this.$axios.Post(this.$api.userRestPwd, {
            "userId": row.id,
            "name": value
          }).then(() => {
            this.$message({
              type: 'success',
              message: '重置成功!'
            });
          }).catch((err) => {
            if (err.errMsg) {
              this.$message({
                type: 'error',
                message: err.errMsg
              });
            }
          })
        }).catch(() => {});
      },
      //复选框点击事件
      checkChange(e, e1) {
        let keys = this.$refs.tree.getCheckedKeys();
        keys.forEach(item => {
          if (item !== e.id) {
            this.$refs.tree.setChecked(item, false)
          }
        })
        this.searchForm.code = e1 ? e.id : '';
        this.search();
      },
      search() {
        this.getData(1, this.searchForm.limit)
      },
      getData(page, limit) {
        this.loading = true;
        this.$axios.Get(this.$api.userListPageAll, {
          userName: this.searchForm.name,
          companyId: this.searchForm.code,
          page: page,
          limit: limit
        }).then(res => {
          this.searchForm.limit = limit
          this.searchForm.page = page
          this.tableData = res.data;
          this.total = res.count
          this.loading = false;
        }).catch(() => {
          this.loading = false;
        })
      },
      feach(e) {
        this.getData(e.page, e.limit)
      },
      editRow(row) {
        this.formType = true;
        this.dialogVisible = true;
        let data = JSON.parse(JSON.stringify(row))
        var userRoles = {}
        this.findRoleIds(this.roleOptions, data.userRole, userRoles)
        let codes = userRoles.codes.substring(1, userRoles.codes.length - 1).split("],[")
        codes.splice(0, 1)
        let ids = []
        codes.forEach(item => {
          this.findRoleCodes(this.roleOptions, item, ids)
        })
        ids.push(userRoles.id)
        data["userRole"] = ids
        this.form = data
      },
      findRoleIds(e, id, value) {
        e.forEach(item => {
          if (item.value === id) {
            value["id"] = item.value
            value["codes"] = item.data.roleCodes
          } else if (item.children && item.children.length > 0) {
            this.findRoleIds(item.children, id, value)
          }
        })
      },
      findRoleCodes(e, code, value) {
        e.forEach(item => {
          if (item.data.roleCode === code) {
            value.push(item.value)
          } else if (item.children && item.children.length > 0) {
            this.findRoleCodes(item.children, code, value)
          }
        })
      },
      add() {
        this.dialogVisible = true;
      },
      //关闭
      closeAlert() {
        this.dialogVisible = false;
        this.$refs["form"].resetFields();
        this.form.id = "";
        this.loadingAlert = false;
        this.formType = false;
      },
      //表单提交
      onSubmit: function(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.formType ? this.editUser() : this.addUser()
          } else {
            return false;
          }
        });
      },
      //添加信息
      addUser() {
        this.loadingAlert = true
        let params = JSON.parse(JSON.stringify(this.form))
        params["userRole"] = params.userRole[params.userRole.length - 1]
        this.$axios.Post(this.$api.userAdd, params).then(() => {
          this.$message({
            type: 'success',
            message: '添加成功!'
          });
          this.closeAlert();
          this.getData(this.searchForm.page, this.searchForm.limit)
        }).catch((err) => {
          if (err.errMsg) {
            this.$message({
              type: 'error',
              message: err.errMsg
            });
          }
          this.loadingAlert = false
        })
      },
      //编辑信息
      editUser() {
        this.loadingAlert = true
        let params = JSON.parse(JSON.stringify(this.form))
        params["userRole"] = params.userRole[params.userRole.length - 1]
        params['phone'] = params.userPhone
        params['userId'] = params.id
        this.$axios.Post(this.$api.userModify, params).then(() => {
          this.$message({
            type: 'success',
            message: '修改成功!'
          });
          this.closeAlert();
          this.getData(this.searchForm.page, this.searchForm.limit)
        }).catch((err) => {
          if (err.errMsg) {
            this.$message({
              type: 'error',
              message: err.errMsg
            });
          }
          this.loadingAlert = false
        })
      },
      //删除表格数据
      delRow: function(row) {
        this.$confirm('确认要删除该条信息吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios.Post(this.$api.userRemove, {
            "userId": row.id
          }).then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getData(this.searchForm.page, this.searchForm.limit)
          }).catch((err) => {
            if (err.errMsg) {
              this.$message({
                type: 'error',
                message: err.errMsg
              });
            }
          })
        }).catch(() => {});
      },
      //获取数据
      departTreeAll: function() {
        this.$axios.Post(this.$api.companyList, {}).then(res => {
          let data = res.data
          this.formatTree(data)
          this.departOptions = data
        })
      },
      //获取角色
      getRoleTree() {
        this.$axios.Get(this.$api.roleTree, {}).then(res => {
          let data = res.data
          this.formatRoleTree(data)
          this.roleOptions = data
        })
      },
      //格式化
      formatRoleTree(data) {
        data.forEach(item => {
          item["value"] = item.data.roleId
          if (item.children.length > 0) {
            this.formatRoleTree(item.children)
          } else {
            delete item.children
          }
        })
      },
      //格式化
      formatTree(data) {
        data.forEach(item => {
          item["value"] = item.id
          if (item.children && item.children.length > 0) {
            this.formatTree(item.children)
          } else {
            delete item.children
          }
        })
      }
    },
    components: {
      "table-page": () => import('@/components/TablePage')
    }
  }
</script>

<style lang="less">

</style>
